.sidebar {
  display: block;
  position: fixed;
  width: 100%;
  max-width: var(--sidebar-width);
  top: 0;
  bottom: 0;
  transition: var(--sidebar-transition);
  transition-duration: var(--sidebar-transition-duration);
  transition-timing-function: var(--sidebar-transition-function-ease);
  z-index: 910;
  background-color: var(--bs-gray-900);
  color: var(--#{$variable-prefix}primary);
  box-shadow: 0 0 30px rgba(var(--#{$variable-prefix}primary-rgb), .05);
  .sidebar-body {
      padding-right: 1rem;
      overflow: hidden;
  }
  .data-scrollbar {
      max-height: 87vh;
  }
  .sidebar-header{
      border-bottom: $border-width solid tint-color($border-color, 20%);
      margin-bottom: $spacer * .5;
  }
  .sidebar-logo {
      transition-property: transform, opacity;
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease);
      margin-left: 1rem;
      margin-bottom: 0;
  }
  .navbar-brand {
      display: flex;
      align-items: center;
      padding: 1.73rem $spacer;
      border-bottom: var(--bs-border-width) solid var(--bs-border-color);
      svg{
          color: var(--#{$variable-prefix}primary);
      }
      .logo-title {
          margin-left: $spacer;
          margin-bottom: 0;
          transition: var(--sidebar-transition);
          transition-duration: var(--sidebar-transition-duration);
          transition-timing-function: var(--sidebar-transition-function-ease);
          transform: translateX(0%);
          opacity: 1;
          color: $dark;
      }
  }
  .logo-main{
      .logo-mini{
          min-height: 31px;
          height: 31px;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          transform: translateX(-30px);
      }
      .logo-normal{
          min-height: 36px;
          height: 36px;
          opacity: 1;
        //   transform: translateX(35px);
          transition: all 0.3s ease-in-out;
      }
  }
  .sidebar-toggle {
      position: absolute;
      right: -12px;
      top: 30px;
      cursor: pointer;
      background: var(--#{$variable-prefix}primary);
      color: var(--#{$variable-prefix}white);
      padding: 0.2rem;
      border-radius: $border-radius-pill;
      box-shadow: $box-shadow;
      &:hover {
        box-shadow: $box-shadow;
      }
      .icon {
          display: flex;
          transform: rotate(0);
          transition: var(--sidebar-transition);
          transition-duration: var(--sidebar-transition-duration);
          transition-timing-function: var(--sidebar-transition-function-ease);
      }
  }

  &.sidebar-mini{
      .logo-mini {
          min-height: 31px;
          height: 31px;
          opacity: 1;
          transform: translateX(-75px);
          transition: all 0.3s ease-in-out;
      }
      .logo-normal {
          min-height: 36px;
          height: 36px;
          opacity: 0;
          transition: all 0.3s ease-in-out;
      }

  }

  .logo-main{
      .dark-normal{
          transition: all 0.3s ease-in-out;
          opacity: 0;
          min-height: 35px;
          height: 35px;
      }
      .dark-mini{
          transition: all 0.3s ease-in-out;
          opacity: 0;
          min-height: 35px;
          height: 35px;
      }
  }

}

.sidebar-base {
  .nav-item {
      position: relative;
      transition-property: background-color, color, border-radius, transform, opacity, padding-left, padding-right;
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease);
      color: var(--#{$variable-prefix}gray-600);
      list-style: none;
      margin-top: .5rem;

      &:not(.static-item) {
          padding-left: 1rem;
      }

      &.static-item {
          .default-icon {
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 0.063rem;
            color: var(--bs-heading-color);
            font-weight: 500;
            font-family: var(--#{$prefix}font-monospace);
          }
      }

      .disabled {
        color: var(--#{$variable-prefix}gray-400);
        cursor: unset !important;
      }

      .nav-link {
          transition-property: background-color, color, border-radius, transform, opacity;
          transition-duration: var(--sidebar-transition-duration);
          transition-timing-function: var(--sidebar-transition-function-ease);
          cursor: pointer;
          line-height: 1.5;
          color: inherit;
          &.active {
              transition-property: background-color, color, border-radius, transform, opacity;
              transition-duration: var(--sidebar-transition-duration);
              transition-timing-function: var(--sidebar-transition-function-ease);
              color: var(--#{$variable-prefix}primary);
          }

          &:hover {
              color: var(--#{$variable-prefix}primary);
          }

          .icon{
              font-size: 1.125rem;
              color: var(--bs-heading-color);
          }

          &.static-item {
              margin-bottom: 0.5rem;
              margin-top: 1rem;
              padding: 0 1rem;
              color: var(--bs-heading-color);
              .mini-icon {
                  display: none;
              }
          }

          .item-name {
              flex: 1;
              margin-left: .75rem;
              transform: translateX(0);
              opacity: 1;
          }

          .sidenav-mini-icon {
              opacity: 0;
              display: none;
          }
          text-transform: capitalize;
          &:not(.disabled) {
              .right-icon {
                  transition: var(--sidebar-transition);
                  transition-duration: var(--sidebar-transition-duration);
                  transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                  display: flex;
              }
              .icon{
                  display: flex;
              }
              color: var(--bs-body-color);
              &.active,&[aria-expanded=true]{
                  .right-icon {
                      transition: var(--sidebar-transition);
                      transition-duration: var(--sidebar-transition-duration);
                      transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                      transform: rotate(90deg);
                  }
              }
              &.active{
                  &[aria-expanded=false]{
                      .right-icon {
                          transition: var(--sidebar-transition);
                          transition-duration: var(--sidebar-transition-duration);
                          transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                          transform: rotate(0deg);
                      }
                  }
              }
          }
          padding: .657rem 1rem;
          display: flex;
          align-items: center;
          white-space: nowrap;
          text-transform: capitalize;
      }
  }

  .sub-nav {
      padding: 0;
      margin: 0;
      overflow: hidden;
      transition: var(--sidebar-transition);
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease-in-out);
  }
}

@include media-breakpoint-down(xl){
    .sidebar {
        .logo-main{
            .logo-normal{
                transform: translateX(30px);
            }
        }
    }
}

body{
  --sidebar-width: #{$navbar-vertical-width};
  --sidebar-transition: #{$navbar-vertical-transition};
  --sidebar-transition-duration: #{$navbar-vertical-transition-duration};
  --sidebar-transition-function-ease: #{$navbar-vertical-transition-function-ease};
  --sidebar-transition-function-ease-in-out: #{$navbar-vertical-transition-function-ease-in-out};
}
