
.sidebar {
    &.sidebar-default {
        .nav-link {
            &:not(.static-item){
                --shadow-dark-color: var(--#{$variable-prefix}primary-rgb);
                &.active, &[aria-expanded=true] {
                    background: var(--#{$variable-prefix}primary);
                    color: var(--#{$variable-prefix}white);
                    .icon {
                        color: var(--#{$variable-prefix}white);
                    }
                }
                &:hover {
                    &:not(.active):not([aria-expanded=true]) {
                        background: var(--#{$variable-prefix}primary-bg-subtle);
                        color: var(--#{$variable-prefix}primary);
                        box-shadow: unset;
                        .icon {
                            color: var(--#{$variable-prefix}primary);
                        }
                    }
                    
                }
            }
        }
        .sub-nav {
            .nav-link {
                &:not(.static-item){
                    --shadow-dark-color: var(--#{$variable-prefix}primary-rgb);
                    &.active, &[aria-expanded=true] {
                        background: var(--#{$variable-prefix}primary-bg-subtle);
                        color: var(--#{$variable-prefix}primary);
                        .icon {
                            color: var(--#{$variable-prefix}primary);
                        }
                    }
                    &:hover {
                        &:not(.active):not([aria-expanded=true]) {
                            background: var(--#{$variable-prefix}primary-bg-subtle);
                            color: var(--#{$variable-prefix}primary);
                            box-shadow: unset;
                            .icon {
                                color: var(--#{$variable-prefix}primary);
                            }
                        }
                        
                    }
                }
            }
        }
    }
}
