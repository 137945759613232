[data-bs-theme="dark"] {
    .sidebar {
        .logo-main {
            .logo-normal.dark-normal {
                min-height: 36px;
                height: 36px;
                opacity: 1;
                transform: translateX(-125px);
                transition: all 0.3s ease-in-out;
            }

            .logo-mini.dark-mini {
                opacity: 0;
                min-height: 31px;
                height: 31px;
                transition: all 0.3s ease-in-out;
            }
        }

        .logo-main {
            .logo-mini {
                min-height: 31px;
                height: 31px;
                transition: all 0.3s ease-in-out;
                opacity: 0;
            }

            .logo-normal {
                min-height: 36px;
                height: 36px;
                opacity: 0;
                transition: all 0.3s ease-in-out;
            }
        }

        &.sidebar-mini {
            .dark-mini.logo-mini {
                min-height: 31px;
                height: 31px;
                opacity: 1;
                transform: translateX(-110px);
                transition: all 0.3s ease-in-out;
            }

            .dark-normal.logo-normal {
                min-height: 36px;
                height: 36px;
                opacity: 0;
                transition: all 0.3s ease-in-out;
            }
        }
    }

}