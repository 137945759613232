[data-bs-theme="dark"] {
    $gray-900: #202020;
    --#{$prefix}gray-900: #{$gray-900};
    --#{$prefix}gray-900-rgb: #{to-rgb($gray-900)};
    $gray-800: #343a40;
    --#{$prefix}gray-800: #{$gray-800};
    --#{$prefix}gray-800-rgb: #{to-rgb($gray-800)};
    $gray-700: #050c21;
    --#{$prefix}gray-700: #{$gray-700};
    --#{$prefix}gray-700-rgb: #{to-rgb($gray-700)};
    $gray-600: #d7dbdf;
    --#{$prefix}gray-600: #{$gray-600};
    --#{$prefix}gray-600-rgb: #{to-rgb($gray-600)};
    $gray-500: #2f2f2f;
    --#{$prefix}gray-500: #{$gray-500};
    --#{$prefix}gray-500-rgb: #{to-rgb($gray-500)};
    $gray-400: #1c1e2f;
    --#{$prefix}gray-400: #{$gray-400};
    --#{$prefix}gray-400-rgb: #{to-rgb($gray-400)};
    $gray-300: #222738;
    --#{$prefix}gray-300: #{$gray-300};
    --#{$prefix}gray-300-rgb: #{to-rgb($gray-300)};
    $gray-200: #181818;
    --#{$prefix}gray-200: #{$gray-200};
    --#{$prefix}gray-200-rgb: #{to-rgb($gray-200)};
    $gray-100: #181818;
    --#{$prefix}gray-100: #{$gray-100};
    --#{$prefix}gray-100-rgb: #{to-rgb($gray-100)};

    // Shadow
    --#{$prefix}box-shadow: 0 0.063rem 1.875rem 0 rgba(#{to-rgb($black)}, 0.3);
    --#{$prefix}box-shadow-sm: 0 0.125rem 0.25rem rgba(#{to-rgb($black)}, 0.1);
    --#{$prefix}box-shadow-lg: 0 0.625rem 1.875rem rgba(#{to-rgb($black)}, 0.5);
    --#{$prefix}box-shadow-inset: inset 0 0.25rem 0.5rem rgba(#{to-rgb($black)}, 0.16);
}


