.btn {
    &.btn-border {
        color: var(--bs-gray);
        border: $border-width * 1 solid var(--bs-border-color);
        padding: $spacer*.5 $spacer;
        &:hover {
            background: transparent;
            border-color: var(--#{$variable-prefix}primary) !important;
            color: var(--#{$variable-prefix}primary);
        }
        .btn-check:checked + &,
        .btn-check:active + &,
        &:active, &.active{
            background: var(--#{$variable-prefix}primary);
            color: var(--#{$variable-prefix}white);
            border-color: var(--#{$variable-prefix}primary);
            &.bg-transparent{
                color: var(--#{$variable-prefix}primary);
            }
        }
    }
}
